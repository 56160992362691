// \\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
// OTRM Theme
// ORE (ORE)
const Theme = {

    styles: {
        fontWeight: 'normal',
        textColor: '#00dfff',
        textInvertedColor: '#000000',
        background: 'linear-gradient(0deg, #000708, #001114)',
        scrollbarColor: 'rgba(0, 223, 255, 0.5)',
        linkColor: 'rgba(255, 255, 255, 0.5)',
        borderColor: '#00dfff',
        borderSize: 2,
        margin: 10,
        padding: 10,
        versionOpacity: 0.5,
        versionDistance: 30,
        versionTextColor: '#00dfff',
        headerPadding: 20,
        headerOpacity: 0.8,
        headerBackground: '#00dfff',
        headerDistance: 10,
        headerTextColor: '#000000',
        headerItemDividerWidth: 3,
        headerButtonBackground: '#00dfff',
        headerButtonTextColor: '#000000',
        headerBottomFill: 10,
        headerBottomRounded: 5,
        headerTopFill: 10,
        headerTopRounded: 5,
        actionButtonTextColor: '#000000',
        actionButtonBackground: '#00dfff',
        actionButtonBorderColor: '#00dfff',
        actionButtonBorderSize: 3,
        actionButtonBorderDistance: 2,
        actionButtonBorderRadius: 10,
        columnPadding: 20,
        columnBorderRadius: 30,
        columnInnerPadding: 10,
        columnBorder: '3px solid #00dfff',
        columnInnerBorder: '8px solid rgba(0, 223, 255, 0.4)',
        columnBackground: 'rgba(25, 25, 25, 0.25)',
        invertedBackgroundColor: '#000000',
        invertedTextColor: '#FFFFFF',
        invertedBorderRadius: 3,
        listItemTextColor: '#FFFFFF',
        listItemBackground: 'rgba(0, 223, 255, 0.15)',
        listItemBorder: '5px solid rgba(0, 223, 255, 0.3)',
        listItemSelectedBackground: 'rgba(0, 223, 255, 0.3)',
        listItemSelectedBorder: '5px solid rgba(0, 223, 255, 1)',
        listItemBorderRadius: 10,
        listItemFontSize: '1vw',
        listItemImageSize: '10vw',
        listItemImageBorder: '1px solid rgba(0, 223, 255, 0.4)',
        listItemHighlightTextColor: '#000000',
        listItemHighlightBorder: '1px solid #000000',
        listItemHighlightBorderRadius: 3,
    },
};

export default Theme;

