// \\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\
// OTRM Theme
// REST AND RELAX (RNR)
const Theme = {
    styles: {
        fontWeight: 'bold',
        textColor: '#000000',
        textInvertedColor: '#000000',
        background: 'linear-gradient(0deg, #FFBB00, #FF9900)',
        scrollbarColor: '#000000',
        linkColor: 'rgba(255, 255, 255, 0.5)',
        borderColor: '#000000',
        borderSize: 2,
        margin: 10,
        padding: 10,
        versionOpacity: 1,
        versionDistance: 30,
        versionTextColor: '#FFAA00',
        versionBackground: '#000000',
        versionBorder: 'none',
        headerOpacity: 1,
        headerBottomFill: 10,
        headerTopFill: 2,
        headerBackground: '#000000',
        headerTextColor: '#FFAA00',
        headerItemDividerWidth: 2,
        headerButtonBackground: '#000000',
        headerButtonTextColor: '#FFAA00',
        actionButtonTextColor: '#FFFFFF',
        actionButtonBackground: '#000000',
        actionButtonBorderColor: '#000000',
        actionButtonBorderSize: 2,
        actionButtonBorderDistance: 3,
        actionButtonBorderRadius: 5,
        columnPadding: 10,
        columnBorderRadius: 10,
        invertedBackgroundColor: '#000000',
        invertedTextColor: '#FFFFFF',
        invertedBorderRadius: 10,
        listItemTextColor: '#000000',
        listItemBackground: 'transparent',
        listItemBorder: '5px solid rgba(0, 0, 0, 0.5)',
        listItemSelectedBackground: 'rgba(0, 0, 0, 0.25)',
        listItemSelectedBorder: '5px solid #000000',
        listItemBorderRadius: 10,
        listItemFontSize: '1vw',
        listItemImageSize: '10vw',
        listItemImageBorder: '1px solid rgba(0, 0, 0, 0.25)',
        listItemHighlightTextColor: '#000000',
        listItemHighlightBorder: '1px solid #000000',
        listItemHighlightBorderRadius: 3,
    },
};

export default Theme;
